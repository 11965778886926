import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				 Ocean's Embrace Charters
			</title>
			<meta name={"description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:title"} content={" Ocean's Embrace Charters"} />
			<meta property={"og:description"} content={"Ocean's Embrace Charters спеціалізується на створенні розкішних і незабутніх чартерів яхт. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"} />
		</Helmet>
		<Components.Marque />
		<Components.Header />
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14:03:12.504Z"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						max-height="500px"
						srcSet="https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-1.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					width="50%"
					padding="0px 80px 0px 0"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
					order="-1"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Підготуйте свою персональну морську подорож
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						У Ocean's Embrace Charters ми пишаємося тим, що пропонуємо комплекс послуг, покликаних зробити кожну подорож незабутньою. Незалежно від того, чи плануєте ви спокійний усамітнений відпочинок або пишний захід на морі, наш широкий спектр індивідуальних рішень гарантує, що ваші потреби будуть не тільки задоволені, але й перевершені.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14:03:12.506Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					srcSet="https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-3%20%281%29.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					<Strong>
						Наш флот
						<br />
					</Strong>
					Наш флот є основою нашої діяльності і включає в себе судна від елегантних вітрильників до розкішних яхт. Кожне з них оснащене найсучаснішими технологіями навігації та безпеки, розкішними зручностями та інтер'єрами, створеними на замовлення для комфорту та елегантності. Наші судна - це не просто засоби для подорожей, це ваші приватні плавучі святилищ
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						<Strong>
							Оздоровчі послуги:
						</Strong>
						{" "}Посильте свій відпочинок за допомогою оздоровчих і спа-процедур на борту.{"\n"}
						<br />
						<Strong>
							{"    "}Масажна терапія:{" "}
						</Strong>
						Розслабтеся за допомогою різноманітних видів масажу від сертифікованих професіоналів.{"\n"}
						<br />
						<Strong>
							{"    "}Заняття йогою:{" "}
						</Strong>
						Зустрічайте світанок під керівництвом інструктора з йоги на палубі.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14:03:12.521Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/2-2.jpg?v=2024-07-18T14%3A03%3A12.521Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14:03:12.506Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					max-height="400px"
					srcSet="https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-4.jpg?v=2024-07-18T14%3A03%3A12.506Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--base"
				>
					<Strong>
						Харчування на борту
					</Strong>
					: Вишукані страви, приготовані досвідченими кухарями з урахуванням ваших дієтичних уподобань та кулінарної допитливості.{"\n"}
					<br />
					<Strong>
						{"    "}Місцеві делікатеси
					</Strong>
					: Насолоджуйтесь ароматами регіону зі стравами, приготованими з місцевих інгредієнтів.{"\n"}
					<br />
					<Strong>
						{"    "}Вишукані страви:{" "}
					</Strong>
					Скуштуйте вишукані вечері з кількох страв під відкритим небом.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--base" sm-text-align="left">
						<Strong>
							Водні види спорту та розваги:{" "}
						</Strong>
						Від снорклінгу до катання на водних мотоциклах - урізноманітніть свій чартер захоплюючими водними пригодами.{"\n"}
						<br />
						<Strong>
							{"    "}Спорядження, що надається:
						</Strong>
						{" "}Високоякісне спорядження для всіх видів діяльності, що підходить для всіх рівнів підготовки.{"\n"}
						<br />
						<Strong>
							{"    "}Професійні інструктори:
						</Strong>
						{" "}Експертні поради, які збагатять ваш досвід занять водними видами спорту.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://uploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14:03:12.504Z"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					srcSet="https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6699200a9710450018a16614/images/1-3.jpg?v=2024-07-18T14%3A03%3A12.504Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Дізнайтеся більше з нами
			</Override>
			<Override slot="text1">
				Цей огляд лише зачіпає поверхню того, що пропонує Ocean's Embrace Charters. Щоб ознайомитися з повним спектром наших послуг і спланувати свою морську подорож на замовлення, ми запрошуємо вас зв'язатися з нами. Наша команда готова допомогти вам розробити ідеальну яхтову подорож, яка перевершить всі ваші очікування.
			</Override>
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});